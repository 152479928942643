@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Pacifico&family=Quicksand:wght@400;500;600&family=Raleway:wght@400;600;700;800;900&family=Roboto:wght@400;500&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Hero height */
.container-screen {
  height: calc(100vh - 90px);
}
@media (max-width: 1478px) {
  .container-screen {
    height: 100%;
  }
}

/* style horizontal rule on signup/signin */
.hr-content::before {
  content: "OR";
  display: block;
  padding: 10px;
  background-color: white;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

/* SWIPPER STYLES */

/* On swipe of new carosal */
.swiper-slide.swiper-slide-next + .swiper-slide-visible .backdrop,
.swiper-slide.swiper-slide-visible:has(+ .swiper-slide-prev) .backdrop {
  transition: all 300ms ease-in-out 300ms;
  border-radius: 22px;
  right: -3px;
  left: -3px;
  bottom: -3px;
  top: -3px;
  backdrop-filter: blur(4.5px);
  background-color: rgba(34, 58, 89, 0.1);
}
.swiper-slide.swiper-slide-next + .swiper-slide-visible > *,
.swiper-slide.swiper-slide-visible:has(+ .swiper-slide-prev) > * {
  pointer-events: none;
}

/* Swipper BUTTON styles and overide */

/* remove default button */
.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}
.swiper-button-next,
.swiper-button-prev {
  background-repeat: no-repeat;
  background-position: center center;
  /* Overide default height and width */
  width: 48px !important;
  height: 48px !important;
  border-radius: 100%;
  background-color: yellow;
  transition: background 400ms ease-in-out;
}

/* On full screen position of buttons */
.swiper-button-next {
  right: var(--swiper-navigation-sides-offset, 6%) !important;
  background-image: url("/public/assets/icons/arrow-right.png");
}
.swiper-button-prev {
  left: var(--swiper-navigation-sides-offset, 4%) !important;
  background-image: url("/public/assets/icons/arrow-left.png");
}

/* grid for requirement number columbs */
.grid-requirement {
  display: grid;
  grid-template-columns: 10px 1fr;
}

/* global view heights and widths*/
.h-vh {
  height: calc(100vh - 90px);
}
.min-vh {
  min-height: calc(100vh - 90px);
}
.max-vw {
  max-width: calc(100vw - 336px);
}

/* navbar styling */
.navbarlink {
  @apply flex h-full justify-center w-fit transition duration-500 delay-75 ease-in-out;
}
.navbarlink.active {
  @apply border-b border-secondary font-bold text-secondary duration-500 delay-75 ease-in-out;
}

/* switch check slider styling */
.check-slider {
  @apply absolute rounded-[50px] right-0 left-0 bottom-0 top-0 peer-checked:bg-[#34c759] bg-[#E9E9E9] before:w-[27px] before:absolute before:h-[27px];
  @apply before:bg-white before:rounded-[50px] before:top-1/2 before:translate-y-[-50%] before:left-[2px] peer-checked:before:translate-x-[20px];
  @apply transition delay-75 duration-500 ease-in-out before:transition before:duration-500 before:ease-in-out cursor-pointer;
}
.check-slider::before {
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.15);
}

/* sidebar styling */
.sidelink-items > *:not(:last-child) {
  @apply border-b;
}

a.sidelink {
  @apply py-4 pr-[6px] items-center justify-between flex;
}
a.sidelink.active {
  @apply text-orange;
}

/* back button styling */
.back-button::before {
  content: "";
  background-image: url("/public/assets/icons/back-arrow.png");
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
}

/* logout styling */
.logout-button::after {
  content: "";
  background-image: url("/public/assets/icons/logoutIcon.png");
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
}

/* judge button switch  */
.judge-switch {
  font-family: "open sans";
  font-weight: 500;
}

.judge-switch::before {
  content: "";
  position: absolute;
  cursor: pointer;
  height: 36px;
  top: 50%;
  right: 2px;
  width: 50%;
  border-radius: 11px;
  transform: translateY(-50%);
  background-color: white;
  transition: all 400ms ease-in-out;
}

.judge-switch.active::before {
  right: -2px;
  transform: translate(-100%, -50%);
}

.judge-switch .prev,
.judge-switch .next {
  transition: all 300ms ease-in-out;
}

.judge-switch:not(.active) .prev,
.judge-switch.active .next {
  font-weight: 400;
  color: #59595990;
}
